<template>
  <div class="rule">
    <page-block :title="$t('alg.title')" class="rule-block">
      <base-loader v-if="loading && !isReady && type === 'edit'" class="base-btn__loader" />
      <main-card v-else>
        <!--        <div class="l-col">{{ $t('text') }}</div>-->
        <div class="l-col">
          <template v-for="item in fields">
            <base-radio
              v-for="opt in item.options"
              :key="opt.v"
              v-model="formData[item.name]"
              :value="opt.k"
              :name="item.name"
              class="rule-block__field"
            >
              {{ opt.v }}
            </base-radio>
          </template>
        </div>
        <div class="l-col">
          {{ $t(`alg.${formData.atype}${checkedAll ? '_sticky' : ''}`) }}
        </div>
        <div v-if="(rule && rule.protocol.includes('HTTP')) || protocol.includes('HTTP')">
          <div class="l-col">
            <base-checkbox :model-value="checkedAll" @change="onSelectAllChanged">
              {{ $t('sticky') }}
            </base-checkbox>
          </div>
          <div v-if="checkedAll">
            <div class="l-col">
              <base-select
                :value="typeCookie"
                :options="sticky"
                :searchable="false"
                :custom-label="refactName"
                :allow-empty="false"
                open-direction="top"
                field-label="Идентифицировать сессию"
                class="select"
                :show-labels="false"
                @input="onChange"
              />
            </div>
            <div v-if="typeCookie === 'APP_COOKIE'" style="max-width: 22rem" class="l-col">
              <base-input
                v-model="cookie_name"
                type="text"
                required
                class="form__field--input"
                :label="$t('label')"
                @input="onChangeCookie"
              >
              </base-input>
            </div>
            <div class="l-col">
              {{ $t(`alg.${formData.session_persistence.type}`) }}
            </div>
          </div>
        </div>
        <div
          v-if="hasChanges && type === 'edit'"
          slot="footerEnd"
          class="rule-block__actions"
          @click="updateRule"
        >
          <base-button
            :disabled="typeCookie === 'APP_COOKIE' && cookie_name === ''"
            class="rule-block__btn"
          >
            {{ $t('alg.btn.save') }}
          </base-button>
          <base-button theme="outlined" class="rule-block__btn" @click="clearData">
            {{ $t('alg.btn.cancel') }}
          </base-button>
        </div>
      </main-card>
    </page-block>
  </div>
</template>

<script>
import specsFormatting from '@/mixins/specsFormatting';
import BaseRadio from '@/components/Checkboxes/BaseRadio';
import BaseCheckbox from '@/components/Checkboxes/BaseCheckbox';
import BaseSelect from '@/components/Select/BaseSelect';
import { isEqual } from 'lodash';
import BaseInput from '@/components/BaseInput/BaseInput';
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import showErrorModal from '@/mixins/showErrorModal';
export default {
  name: 'RuleAlgorithm',
  components: { BaseRadio, BaseLoader, BaseInput, BaseCheckbox, BaseSelect },
  mixins: [showErrorModal],
  props: {
    ruleid: {
      type: String,
      default: '',
    },
    dataset: {
      type: Array,
      default: () => [],
    },
    loadingProps: {
      type: Boolean,
      default: true,
    },
    id: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: 'edit',
      validator: val => ['edit', 'create'].includes(val),
    },
    protocol: {
      type: String,
      default: '',
    },
    rule: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      initialBackup: {},
      loading: this.loading,
      isReady: false,
      hasChangeAlgorithm: false,
      hasChangeSticky: false,
      hasChangeCookie: false,
      hasChangeStickyType: false,
      hasChangeStickyName: false,
      hideButtons: true,
      formData: {},
      typeCookie: null,
      cookie_name: '',
      alg: '1',
      stickySessions: false,
      checkedAll: false,
      sticky: ['HTTP_COOKIE', 'SOURCE_IP', 'APP_COOKIE'],
      fields: [
        {
          key: 'atype',
          name: 'atype',
          options: [
            { k: 'atany', v: 'ROUND_ROBIN' },
            { k: 'atallow', v: 'LEAST_CONNECTIONS' },
          ],
          type: 'radio',
          value: 'atallow',
        },
      ],
    };
  },
  computed: {
    algoritm() {
      return this.$store.state.moduleStack.lb_algorithm.filter(x => x.type === 'alg');
    },
    hasChanges() {
      return (
        this.hasChangeAlgorithm ||
        (this.hasChangeCookie && this.typeCookie === 'APP_COOKIE') ||
        this.hasChangeSticky ||
        this.hasChangeStickyType ||
        this.hasChangeStickyName
      );
    },
    isViewCookieName() {
      return this.typeCookie === 'APP_COOKIE' && this.cookie_name === '';
    },
  },
  watch: {
    typeCookie: {
      handler: function (event) {
        if (
          event &&
          this.type === 'edit' &&
          this.rule.session_persistence &&
          this.rule.session_persistence.type
        ) {
          this.hasChangeStickyType = event !== this.rule.session_persistence.type;
        } else {
        }
      },
      immediate: false,
    },
    isViewCookieName: {
      handler: function (event) {
        this.$emit('update-view-cookie-name', !event);
      },
      immediate: true,
    },
    cookie_name: {
      handler: function (newValue, oldValue) {
        if (
          this.rule &&
          this.type === 'edit' &&
          !!this.rule.session_persistence &&
          !!this.rule.session_persistence.cookie_name
        ) {
          oldValue = this.rule.session_persistence.cookie_name;
        }
        this.hasChangeCookie = newValue !== oldValue && this.typeCookie === 'APP_COOKIE';
      },
      immediate: false,
    },
    'formData.lb_algorithm': {
      handler: function (event) {
        if (event && this.type === 'edit') {
          this.hasChangeAlgorithm = event !== this.rule.lb_algorithm;
        }
      },
      immediate: false,
    },
    rule: {
      handler: function (event) {
        this.isReady = !!event && !!Object.keys(event).length;
      },
      immediate: true,
    },
    'formData.atype': {
      handler: function (event) {
        this.formData.lb_algorithm = event === 'atany' ? 'ROUND_ROBIN' : 'LEAST_CONNECTIONS';
        this.hasChangeAlgorithm = event !== this.initialBackup.atype;
      },
      immediate: true,
    },
    checkedAll: {
      handler: function (event) {
        if (event) {
          if (!!this.formData.session_persistence && !this.formData.session_persistence.type) {
            this.formData.session_persistence.type = 'HTTP_COOKIE';
          }
          this.hasChangeSticky = event !== this.stickySessions;
          this.hideButtons = event === this.stickySessions;
        } else {
          this.hasChangeSticky = event !== this.stickySessions;
        }
        if ((event || !event) && this.type === 'create') {
          this.preparePool(this.formData);
        }
      },
      immediate: false,
    },
    formData: {
      handler: function (event) {
        if (event && this.type === 'create') {
          this.preparePool(event);
        }
      },
      immediate: true,
      deep: true,
    },
    'formData.session_persistence': {
      handler: function (event) {
        if (event && this.type === 'create') {
        }
      },
      immediate: true,
      deep: true,
    },
    isReady: {
      handler: async function (event) {
        if (event && this.fields.length) await this.setInitialFormData(this.fields);
      },
      immediate: true,
      deep: true,
    },
    protocol: {
      handler: function (event) {
        if (event && this.type === 'create') {
          this.preparePool(this.formData);
        }
      },
      immediate: false,
    },
  },
  async mounted() {
    if (this.type === 'create') await this.setInitialFormData(this.fields);
  },
  beforeDestroy() {
    this.clear();
  },
  methods: {
    preparePool(data) {
      const isCookieName = this.typeCookie === 'APP_COOKIE';
      const isSticky = this.checkedAll;
      const pool = {
        lb_algorithm: data.lb_algorithm,
        session_persistence: {},
      };
      if (isSticky) pool.session_persistence.type = data.session_persistence.type;
      if (isCookieName)
        pool.session_persistence.cookie_name = data.session_persistence.cookie_name
          .trim()
          .replaceAll(' ', '');
      this.$emit('add-algorithm', { id: this.id, pool: pool });
    },
    async setInitialFormData(list) {
      this.loading = true;
      if (this.type === 'edit') {
        if (this.rule.protocol.includes('HTTP') && !this.sticky.includes('APP_COOKIE'))
          this.sticky.push('APP_COOKIE');
        const test = Object.values(this.fields[0].options).find(
          x => x.v === this.rule.lb_algorithm
        ).k;
        this.fields[0].value = test;
        this.formData = list.reduce((acc, item) => {
          acc[item.name] = item.value;
          return acc;
        }, {});
        this.checkedAll =
          !!this.rule &&
          !!this.rule.session_persistence &&
          !!Object.keys(this.rule.session_persistence).length;
        this.stickySessions = !!this.checkedAll;
        this.formData.lb_algorithm = this.rule.lb_algorithm;
        if (this.checkedAll) {
          this.formData.session_persistence = Object.assign({}, this.rule.session_persistence);
          this.typeCookie = this.rule.session_persistence.type;
          this.cookie_name = this.rule.session_persistence.cookie_name || '';
        } else {
          this.formData.session_persistence = {};
          this.typeCookie = this.sticky[0];
          this.cookie_name = '';
        }
      } else {
        if (this.protocol.includes('HTTP') && !this.sticky.includes('APP_COOKIE'))
          this.sticky.push('APP_COOKIE');
        this.formData = {
          lb_algorithm: 'ROUND_ROBIN',
          atype: 'atany',
          session_persistence: {
            cookie_name: '',
            type: 'HTTP_COOKIE',
          },
        };
        this.typeCookie = this.sticky[0];
        this.cookie_name = '';
      }
      this.initialBackup = Object.assign({}, this.formData);
      this.initialBackup.session_persistence = Object.assign({}, this.formData.session_persistence);
      this.loading = false;
    },
    clear() {
      this.formData = {};
      this.initialBackup = {};
    },
    refactName(name) {
      return name.replace('_', '-');
    },
    clearData() {
      this.hasChangeAlgorithm = false;
      this.hasChangeSticky = false;
      this.hasChangeCookie = false;
      this.hasChangeStickyType = false;
      this.hasChangeStickyName = false;

      this.formData.lb_algorithm = this.initialBackup.lb_algorithm;
      this.setInitialFormData(this.fields);
    },
    onChange(val) {
      this.formData.session_persistence.type = val;
      this.typeCookie = val;
    },
    onChangeCookie(val) {
      this.formData.session_persistence.cookie_name = val;
      this.cookie_name = val;
    },
    updateRule() {
      this.loading = true;
      const params = {
        id: this.rule.id,
        pool: {
          lb_algorithm: this.formData.lb_algorithm,
        },
      };
      if (this.checkedAll)
        params.pool.session_persistence = {
          type: this.typeCookie,
          cookie_name: this.cookie_name,
        };
      else params.pool.session_persistence = null;
      if (this.checkedAll && params.pool.session_persistence.type !== 'APP_COOKIE')
        delete params.pool.session_persistence.cookie_name;
      this.$store
        .dispatch('moduleStack/updateRule', params)
        .then(data => {
          console.log(data);
        })
        .catch(e => {
          console.log(e);
          this.showError(e.response.data.faultstring);
          this.setInitialFormData(this.fields);
        })
        .finally(() => {
          this.loading = false;
          this.clearData();
        });
    },
    onSelectAllChanged(val) {
      if (val && this.rule && this.rule.session_persistence && this.rule.session_persistence.type) {
        this.formData.session_persistence = Object.assign({}, this.rule.session_persistence);
        this.typeCookie = this.rule.session_persistence.type;
        this.cookie_name = this.rule.session_persistence.cookie_name || '';
      } else if (
        val &&
        this.rule &&
        this.rule.session_persistence &&
        !this.rule.session_persistence.type
      ) {
        this.typeCookie = 'HTTP-COOKIE';
        this.cookie_name = '';
      } else if (
        !val &&
        this.rule &&
        this.rule.session_persistence &&
        this.rule.session_persistence.type
      ) {
        this.typeCookie = 'HTTP-COOKIE';
        this.cookie_name = '';
      }
      this.checkedAll = val;
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "sticky": "Sticky sessions",
    "label": "Имя cookie",
    "text": "Балансировщик будет отслеживать состояние балансируемых серверов и перенаправит соединения только на доступные серверы.",
    "alg": {
      "title": "Алгоритм распределения запросов",
      "atany": "Запросы распределяются на серверы по очереди в соответствии с заданным весом.",
      "atany_sticky": "Новые запросы будут распределяться по выбранному алгоритму, все последующие запросы этой сессии будут направляться на тот же сервер, не учитывая выбранный алгоритм.",
      "atallow": "Новый запрос передаётся серверу с наименьшим количеством активных подключений, вес сервера не учитывается.",
      "atallow_sticky": "Новые запросы будут распределяться по выбранному алгоритму, все последующие запросы этой сессии будут направляться на тот же сервер, не учитывая выбранный алгоритм.",
      "HTTP_COOKIE":"Cookie, которую создает и прикрепляет к сессии балансировщик.",
      "SOURCE_IP":"IP-адрес клиента хешируется и делится на вес каждого сервера в правиле — так определяется сервер, который будет обрабатывать запросы.",
      "APP_COOKIE":" Уже существующая cookie, которая задана в коде приложения.",
      "ROUND_ROBIN": {
        "name": "Round Robin",
        "text": "Запросы распределяются на серверы по очереди в соответствии с заданным весом."
      },
      "LEAST_CONNECTIONS": {
        "name": "Least Connections",
        "text": "Запросы распределяются на серверы по очереди в соответствии с заданным весом."
      },
      "text": "Вам доступно создание 5 резервных копий. Свободно слотов – 1.Бэкапы можно создавать вручную или настроить расписание",
      "btn": {
        "save": "Подтвердить",
        "cancel": "Отменить"
      }
    }
  }
}


</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';
.select {
  width: 20rem;
}
.addons {
  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, center);
    }

    & + & {
      margin-top: 1.5rem;

      +breakpoint(sm-and-up) {
        margin-top: 0.4rem;
        padding-top: 0.4rem;
        border-top: 1px solid var(--border);
      }
    }
  }

  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 19rem;
      margin-right: 0.5rem;
    }
  }
}
.rule {
  width: 100%
  +breakpoint(sm-and-up) {
    flexy(flex-start, center);
  }
  &-block {
    &__content {
      //width: 100%;
      min-width: 60rem;
    }

    &__desc {
      //width: 100%;
      min-width: 50rem;
      //max-width: 50rem;

    }
    &__field {
      flex: 1 1 100%;
      margin: 0.5rem;

      +breakpoint(sm-and-up) {
        flex: 0 0 auto;
        margin: 0.75rem;
      }

      &--full {
        +breakpoint(sm-and-up) {
          flex: 0 1 100%;
        }
      }
      &--textarea {
        flex: 1 1 100%;

        +breakpoint(sm-and-up) {
          flex: 0 1 22rem;
        }
      }
    }

    &__actions {
      margin: 0.75rem -0.75rem -0.75rem;
      flexy(flex-start, center, wrap);
    }

    &__btn {
      margin: 0.75rem;

      +breakpoint(xs-only) {
        flex: 1 1 100%;
      }
    }
  }
  .vhost-detail {
    display: flex;
    flex-direction: column;
    min-size(100%);
    flex: 1 1 100%;

    &-view {
      &__block {
        //&--cards {
          max-width: 966px;
        //}
      }
    }
  }
}
</style>
